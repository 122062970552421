<script setup lang="ts">
import { toRef } from 'vue'
import { useField } from 'vee-validate'

/**
 * Общее текстовое поле с поддержкой
 * стилей Bulma. Может использоваться
 * для типов `text`, `email`.
 *
 * Проверка поля не предусмотрена,
 * только на уровне формы. Схема формы.
 *
 * ```vue
 * <BulmaInput
 *   name="name"
 *   type="text"
 *   label="Name"
 * />
 * ```
 */

const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  value: {
    type: String,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  help: {
    type: String,
    default: ''
  },
  successMessage: {
    type: String,
    default: ''
  },
  iconLeft: {
    type: String,
    default: 'i-fa6-solid:square-pen'
  },
  iconLeftShow: {
    type: Boolean,
    default: false
  },
  iconRightShow: {
    type: Boolean,
    default: true
  },
  iconRightSuccess: {
    type: String,
    default: 'i-fa6-solid-check'
  },
  iconRightDanger: {
    type: String,
    default: 'i-fa6-solid:circle-exclamation'
  },
  placeholder: {
    type: String,
    default: ''
  },
  submitted: {
    type: Boolean,
    default: false
  }
})

/**
 * Требуется использовать toRef() для создания реактивной ссылки на
 * свойство `props.name`, которая будет использоваться в `useField`.
 * Это важно, vee-validate должен знать о происходящих изменениях.
 *
 * @see https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
 */
const name = toRef(props, 'name')

/**
 * Никакие проверки поля не происходят, а используется
 * проверка на уровне формы.
 */
const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange
} = useField(name, undefined, {
  initialValue: props.value
})
</script>
<template>
<div class="field" :class="`field-${name}`">
  <label
    class="label"
    :class="{
      [`label-${name}`]: true,
      'has-text-danger': meta.dirty && !meta.valid
    }"
  >
    {{ label }}
  </label>
  <p
    class="control"
    :class="{
      [`control-${name}`]: true,
      'has-icons-right': iconRightShow,
      'has-icons-left': iconLeftShow
    }"
  >
    <input
      :id="name"
      :name="name"
      :type="type"
      :value="inputValue"
      @input="handleChange"
      @blur="handleBlur"
      :disabled="submitted"
      class="input"
      :class="{
        'is-danger has-background-danger-light': meta.dirty && !meta.valid,
        'is-success has-background-success-light': meta.dirty && meta.valid
      }"
    >

    <span
      class="icon is-small is-left"
      v-if="iconLeftShow"
      :class="{
        'has-text-danger': meta.dirty && !meta.valid,
        'has-text-success': meta.dirty && meta.valid
      }"
    >
      <i :class="iconLeft" />
    </span>

    <span
      v-if="iconRightShow"
      class="icon is-small is-right"
      :class="{
        'has-text-danger': meta.dirty && !meta.valid,
        'has-text-success': meta.dirty && meta.valid
      }"
    >
      <i
        class="i-fa6-solid-check"
        v-show="meta.valid"
      />
      <i
        class="i-fa6-solid:circle-exclamation"
        v-show="!meta.valid"
      />
    </span>
  </p>

  <p v-show="help" class="help">
    {{ help }}
  </p>

  <p
    class="help has-text-danger"
    :class="`help-${name}`"
    v-show="errorMessage"
  >
    {{ errorMessage }}
  </p>
</div>
</template>
