<template>
  <p class="help p-4 my-4 border-dotted border-rounded border-bluegray">
    Любая информация переданная через форму, используется
    для ответа и контакта с вами. Заполняя и
    отправляя форму вы подтверждаете, что ознакомились и согласны со
    следующими документами: <a target="_blank" href="/assets/directus/60e1b897-00ab-4b37-a772-c71f34f9ca19">"согласие
    на обработку персональных данных"</a>, <a target="_blank" href="/assets/directus/a854ded0-a557-4a7a-949f-5bdf50bac15f">"политика конфиденциальности"</a>,
    <a target="_blank" href="/assets/directus/d14996d3-04eb-4822-8cd2-60c27211ca4d">"положение о защите персональных данных"</a>.
  </p>
</template>
