<script setup lang="ts">
import { ref } from 'vue'
const icons = ref([
  'i-fa6-solid:rss', 'i-fa6-solid:font', 'i-fa6-regular:calendar',
  'i-fa6-solid:calendar'
])
</script>

<template>
  <div>
    <p>
      Файлы rails не участвуют в конвейере Vite, поэтому
      стили unocss не компилируются. Как временное решение,
      можно добавлять иконки в компонент, включенный в компиляцию.
      Тогда необходимые стили будут добавлены на страницу. Это не решает
      проблему кодирование стилей в контенте страниц. Стили генерируются
      только в приложении vniispk-ui. Для отражения стилей в страницах Rails,
      их надо создавать в `src/styles/*`. Пока так.


    </p>

    <i class="i-fa6-solid:leaf"></i>

    <table class="table">
      <tr v-for="(item, idx) in icons" :key="idx">
        <td>
          <input type="text" readonly :value="item" class="input">
        </td>
        <td>
          <span class="icon">
            <i :class="item"></i>
          </span>
        </td>
        <td class="w-100">
          <input type="text" readonly :value="`<i class=${ item }></i>`" class="input font-mono">
        </td>
      </tr>
    </table>
  </div>
</template>
